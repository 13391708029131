<template>
  <v-app>

    <v-navigation-drawer v-if="['login'].indexOf($route.name) === -1" app v-model="drawer" temporary>
      <v-list dense nav>
        <div v-if="!currentUser" class="navbar-nav ml-auto">
          <li class="nav-item">
            <router-link to="/login" class="nav-link">
              <font-awesome-icon icon="sign-in-alt" />Login
            </router-link>
          </li>
        </div>

        <div v-if="currentUser" class="navbar-nav ml-auto">
          <li class="nav-item">


            <v-list-item v-for="item in items" :to="item.goTo" :key="item.title" link>
              <v-list-item-icon>
                <v-divider></v-divider>
                <v-icon :color="item.color">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>


          </li>
          <v-divider></v-divider>
          <v-list-item to="/profile" link>
            <v-list-item-icon>

              <v-icon color="black">mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> {{ currentUser.username }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item href @click.prevent="logOut" link>
            <v-list-item-icon>

              <v-icon color="black">mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title> LogOut</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

        </div>
      </v-list>

    </v-navigation-drawer>

    <v-app-bar v-if="['login'].indexOf($route.name) === -1" color="primary" class="flex-grow-0" app dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-app-bar-title>Tec. Podologa Camila
        Cacheiro</v-app-bar-title>
    </v-app-bar>

    <v-main >
      <div class="container">
        <router-view />
      </div>
    </v-main>


    <v-footer v-if="['login'].indexOf($route.name) === -1" border app>
      <v-col class="text-center mt-4" cols="12">
        <strong color="red"> Has accedido como "{{ currentUser.username }}" </strong> 
      <strong> | </strong> 
      Tec. Podologa Camila Cacheiro ® {{ new Date().getFullYear() }}

      </v-col>

    </v-footer>

  </v-app>
</template>

<script>
export default {
  name: 'App',
  watch: {
    group() {
      this.drawer = false
    },
  },
  data: () => ({
    drawer: false,
    group: null,
    // Icons List: https://pictogrammers.com/library/mdi/
    items: [
      { title: 'Manejo de Pacientes', icon: 'mdi-account-multiple', color: 'green', goTo: '/pacientes' },
      { title: 'Agregar Pacientes', icon: 'mdi-account', color: 'green', goTo: '/agregar' },
      { title: 'Manejo de Mutualistas', icon: 'mdi-hospital-building', color: 'blue', goTo: '/mutualistas' },
      { title: 'Agregar Mutualista', icon: 'mdi-hospital-building', color: 'blue', goTo: '/mutualistas-add' },
      { title: 'Manejo de Emergencias', icon: 'mdi-ambulance', color: 'red', goTo: '/emergencias' },
      { title: 'Agregar Emergencia', icon: 'mdi-ambulance', color: 'red', goTo: '/emergencias-add' },
      { title: 'Manejo de Patologias', icon: 'mdi-hospital-box', color: 'purple', goTo: '/patologias' },
      { title: 'Agregar Patologias', icon: 'mdi-hospital-box', color: 'purple', goTo: '/patologias-add' },
      { title: 'Seguimiento', icon: 'mdi-magnify-scan ', color: 'red', goTo: '/seguimiento-consultas' },
    ],
  }),
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    // showAdminBoard() {
    //   if (this.currentUser && this.currentUser.roles) {
    //     return this.currentUser.roles.includes('ROLE_ADMIN');
    //   }

    //   return false;
    // },
    // showModeratorBoard() {
    //   if (this.currentUser && this.currentUser.roles) {
    //     return this.currentUser.roles.includes('ROLE_MODERATOR');
    //   }

    //   return false;
    // },
    // showPacientes() {
    //   if (this.currentUser && this.currentUser.roles) {
    //     return this.currentUser.roles.includes('ROLE_MODERATOR');
    //   }

    //   return false;
    // }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  }
};
</script>