import Vue from 'vue';
import Router from 'vue-router';
import Login from './views/Login.vue';
// TODO Refactor components to be imported this way:
// import Patologias from './components/PatologiasList'
// import PatologiasEdit from './components/PatologiaEdit'
Vue.use(Router);

export const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: "login",
      component: Login
    },
    {
      path: '/profile',
      name: 'profile',
      // lazy-loaded
      component: () => import('./views/Profile.vue')
    },
    {
      path: "/pacientes",
      name: "pacientes",
      component: () => import("./components/PacientesList"),
    },
    {
      path: "/pacientes/:id",
      name: "paciente-details",
      component: () => import("./components/PacienteEdit"),
    },
    {
      path: "/agregar",
      name: "agregar",
      component: () => import("./components/PacienteAdd"),
    },
    {
      // TODO refactor the name to ConsultaAdd
      path: "/consulta",
      name: "consulta",
      component: () => import("./components/PacienteConsulta"),
    },
    {
      path: "/consulta/:id",
      name: "consulta-edit",
      component: () => import("./components/PacienteConsultaEdit"),
    },
    {
      path: "/consultaList",
      name: "consultaList",
      component: () => import("./components/PacienteConsultaList"),
    },
    {
      path: "/patologias",
      name: "patologias",
      component: () => import("./components/PatologiasList"),
    },
    {
      path: "/patologias-add",
      name: "patologias-add",
      component: () => import("./components/PatologiaAdd"),
    },
    {
      path: "/patologias/:id",
      name: "patologias-edit",
      component: () => import("./components/PatologiaEdit"),
    },
    {
      path: "/mutualistas",
      name: "mutualistas",
      component: () => import("./components/MutualistasList"),
    },
    {
      path: "/mutualistas-add",
      name: "mutualistas-add",
      component: () => import("./components/MutualistaAdd"),
    },
    {
      path: "/mutualistas/:id",
      name: "mutualistas-edit",
      component: () => import("./components/MutualistaEdit"),
    },
    {
      path: "/emergencias",
      name: "emergencias",
      component: () => import("./components/EmergenciasList"),
    },
    {
      path: "/emergencias-add",
      name: "emergencias-add",
      component: () => import("./components/EmergenciaAdd"),
    },
    {
      path: "/emergencias/:id",
      name: "emergencias-edit",
      component: () => import("./components/EmergenciaEdit"),
    },
    {
      path: "/seguimiento-consultas",
      name: "seguimiento-consultas",
      component: () => import("./components/SeguimientoConsultasList"),
    },
  ]
});


router.beforeEach((to, from, next) => {
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});